import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HomebtnFR from "../components/home.fr";


export default function Work() {
  const query = graphql`
    {
      allContentfulWork(
        filter: { node_locale: { eq: "fr-CA" } }
        sort: { fields: title, order: ASC }
      ) {
        nodes {
          id
          description
          githubLink
          rel
          target
          title
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const projectList = data.allContentfulWork.nodes;
  const projectItem = projectList.map((item) => {
    return (
      <a
        key={item.id}
        href={item.githubLink}
        target={item.target}
        rel={item.rel}
      >
        <div className="on-hover-40 hide-text-block">
          <h3 className="text-white mb-0 font-bold mb-2">{item.title}</h3>
          <p className="size-large text-purple ">{item.description}</p>
        </div>
      </a>
    );
  });

  return (
    <div className="max-width">
      <div className="top-bottom-margin">
        <div className="max-width-70 text-white animate delay02">

          <HomebtnFR></HomebtnFR>

          <div className="max-width">
            <div className="top-bottom-margin animate">{projectItem}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
